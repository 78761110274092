export const state = () => ({
  config: {
    nav: [
      {
        title: "首页",
        href: "/",
        activeReg: /(^\/*)|(^\/$)/,
      },
      {
        title: "产品与服务",
        href: "/products",
        activeReg: /(^\/products)|(^\/$)/,
      },
      {
        title: "典型业绩",
        href: "/achievement",
        activeReg: /(^\/download)|(^\/$)/,
      },
      {
        title: "惠学堂",
        href: "https://appdbpctsyg7624.h5.xiaoeknow.com/p/decorate/homepage",
        activeReg: /(^\/shop)|(^\/$)/,
        isExternalLink: true, //是否是外部链接
      },
      {
        title: "新闻资讯",
        href: "/news",
        activeReg: /(^\/expert)|(^\/$)/,
        // isExternalLink: true, //是否是外部链接
      },
      {
        title: "关于我们",
        href: "/about",
        activeReg: /(^\/about)|(^\/$)/,
      },
    ],
    baseInfo: {
      tel: "400-780-9998",
      email: "zhkj@qq.com",
      address: "河北省石家庄市桥西区中山东路126号凯嘉大厦10楼",
      company: "河北中惠博裕科技有限公司",
      security: {
        text: "冀ICP备14012003号-3",
        code: "13010402001763",
      },
      hzbWxQrCode: "/images/home/hzbWxQrCode.png",
      hzbDyQrCode: "/images/home/hzbDyQrCode.png",
      hzxQrCode: "/images/home/hzxQrCode.png",
      hzbVideoQrCode: "/images/home/hzbVideoQrCode.png",
    },
    friendshiplink: {},
  },
});

export const mutations = {
  setBaseInfo(state, val) {
    state.config.baseInfo = val;
  },
  setLinks(state, val) {
    state.config.friendshiplink = val;
  },
};
