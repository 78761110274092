<template>
    <footer class="footer">
        <experience class="experience"></experience>
        <div class="bottom">
            <div class="content">
                <div class="left">
                    <div class="top">
                        <!-- 相关网站 -->
                        <div class="websites">
                            <div class="title">{{ websites.title }}</div>
                            <a
                                v-for="item in websites.data"
                                :key="item.name"
                                :href="item.href"
                                class="name"
                            >{{ item.name }}</a>
                        </div>
                        <!-- 联系我们 -->
                        <div class="contact">
                            <div class="title">联系我们</div>
                            <div
                                v-for="(item, index) in info"
                                :key="index"
                                class="name"
                            >
                                {{item.title}}: {{item.context}}
                            </div>
                        </div>
                    </div>

                    <div class="bottom">
                        <div class="copy-right">
                            <span class="item">
                                版权声明 {{$store.state.config.baseInfo.company}}
                            </span>

                            <a 
                            class="item"
                            target="_blank"
                            href="https://beian.miit.gov.cn/"
                            >
                                {{$store.state.config.baseInfo.security.text}}
                            </a>

                            <a 
                            class="item" 
                            target="_blank"
                            :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${$store.state.config.baseInfo.security.code}`"
                            >
                                <img class="record" src="/images/record.png"/>
                                冀公网安备 {{$store.state.config.baseInfo.security.code}}号
                            </a>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <div class="list">
                        <div
                            class="item"
                            v-for="(item, index) in qrcode"
                            :key="index"
                        >
                            <div class="qrcode">
                                <img
                                    height="86"
                                    width="86"
                                    :src="item.url"
                                >
                            </div>

                            <div class="name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </footer>
</template>

<script>
import experience from '../../pages/index/component/experience';
export default {
    components: { experience },
    data() {
        return {
            info: [],
            qrcode: [],
            websites: {},
        };
    },
    computed: {
        recordCode() {
            var recordCode = this.$store.state.config.baseInfo.recordUrl || '',
                num = recordCode.replace(/[^\d]/g, '');

            return num;
        },
    },
    methods: {
        init() {
            var that = this;
            this.$nextTick(() => {
                this.info = [
                    {
                        title: '联系电话',
                        context: `${that.$store.state.config.baseInfo.tel}`,
                    },
                    {
                        title: '邮箱',
                        context: `${that.$store.state.config.baseInfo.email}`,
                    },
                    {
                        title: '地址',
                        context: `${that.$store.state.config.baseInfo.address}`,
                    },
                ];

                this.qrcode = [
                    {
                        url: this.$store.state.config.baseInfo.hzbWxQrCode,
                        name: '惠招标微信公众号',
                    },
                    {
                        url: this.$store.state.config.baseInfo.hzbDyQrCode,
                        name: '惠招标抖音号',
                    },
                    {
                        url: this.$store.state.config.baseInfo.hzxQrCode,
                        name: '惠咨询微信公众号',
                    },
                    {
                        url: this.$store.state.config.baseInfo.hzbVideoQrCode,
                        name: '惠招标视频号',
                    },
                ];

                this.websites = {
                    title: '相关网站',
                    data: [
                        { name: '惠招标', href: 'http://www.hbidding.com' },
                        { name: '惠咨询', href: 'http://www.hgchzx.com' },
                        { name: '惠智采', href: 'http://www.smartprocure.cn' },
                        // { name: '惠信E通', href: '' },
                        // { name: '工程保函通', href: 'http://www.bhtong.cn' },
                    ],
                };
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

$textColor: #fff;

.footer {
    .bottom {
        background: #2f354d;
        padding: 38px 0 22px;
        color: $textColor;
    }
    .content {
        display: flex;
        justify-content: space-between;
    }
    .left {
        width: 65%;

        .top {
            display: flex;
            flex-wrap: wrap;
            .websites {
                width: 35%;
            }
            .contact {
                width: 65%;
            }

            .title {
                font-size: 18px;
                line-height: 46px;
            }

            .name {
                color: $textColor;
                display: block;
                line-height: 46px;
            }
        }

        .bottom {
            border-top: 1px solid #7d7d7d;
            padding-top: 32px;
            margin-top: 16px;

            .copy-right {
                line-height: 30px;
                overflow: hidden;

                .item {
                    position: relative;
                    color: white;

                    .record{
                        display: inline-block;
                        height: 19px;
                    }

                    & + .item{
                        margin-left: 1em;
                    }
                }
            }
        }
    }

    .right {
        width: 30%;
        height: 100%;

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item {
                width: 50%;
                display: flex;
                flex-flow: column;
                align-items: center;
                margin-bottom: 30px;

                .qrcode {
                    width: 86px;
                    height: 86px;
                    img {
                        width: 86px;
                    }
                }

                .name {
                    line-height: 1em;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>