<template>
    <div class="container">
        <div class="content">

            <div class="title">
                中惠科技 让工程更智慧
            </div>
            <div class="sub-title">领先的工程建设SAAS服务提供商</div>

            <div>
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="Form"
                    class="form"
                >
                    <el-form-item
                        prop="providername"
                        :rules="newRule('姓名', 'required')"
                    >
                        <el-input
                            class="wd"
                            v-model="form.providername"
                            placeholder="姓名"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="companyname"
                        :rules="newRule('公司名称', 'required')"
                    >
                        <el-input
                            v-model="form.companyname"
                            placeholder="公司名称"
                            clearable
                            class="wd"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="mobile"
                        :rules="[...newRule('手机号码','required','mobile')]"
                    >
                        <el-input
                            v-model="form.mobile"
                            placeholder="手机号码"
                            clearable
                            class="wd"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="productcode"
                        :rules="[{ required: true, message: '请选择咨询产品', trigger: 'change' }]"
                    >
                        <el-select
                            v-model="form.productcode"
                            placeholder="咨询产品"
                            clearable
                            class="wd"
                        >
                            <el-option
                                v-for="item in productTypes"
                                :key="item.diccode"
                                :label="item.dicname"
                                :value="item.diccode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="yzm"
                        :rules="newRule('验证码', 'required')"
                    >
                        <el-input
                            placeholder="请输入验证码"
                            v-model="form.yzm"
                            clearable
                            class="wd"
                        >
                            <template slot="append">
                                <div
                                    @click="getCode"
                                    v-if="showBtn"
                                >获取验证码</div>

                                <div v-else>{{ text }}</div>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="bottom">
                <div
                    class="btn"
                    @click="submitHandler"
                >立即体验</div>
            </div>
        </div>
    </div>
</template>

<script>
import appNode from '@js/app-node';
export default {
    data() {
        return {
            form: {
                providername: '',
                companyname: '',
                mobile: '',
                productcode: '',
                yzm: '',
            },
            productTypes: [],
            rules: {},

            //控制 '获取验证码'/倒计时 的显示
            showBtn: true,
            //倒计时描述
            text: '',
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        submitHandler() {
            this.$refs['Form'].validate((valid) => {
                if (valid) {
                    this.$post(
                        `${this.$store.state.api.kjsUrl}/web/home/intentioncollect.json`,
                        { ...this.form },
                        (data) => {
                            showMsg('提交成功！', 'success');

                            this.showBtn = true;
                            clearInterval(this.int);
                            this.$refs['Form'].resetFields();
                        }
                    );
                } else {
                    showMsgBox('请完善信息！');
                }
            });
        },
        //获取验证码
        getCode() {
            if (this.validateMobile()) {
                this.$post(
                    `${this.$store.state.api.kjsUrl}/sendyzm`,
                    { mobile: this.form.mobile },
                    (data) => {
                        showMsg('发送验证码成功！', 'success');
                        //显示倒计时
                        this.showCountdown();
                    }
                );
            }
        },
        showCountdown() {
            //显示倒计时
            this.showBtn = false;
            //倒计时
            var remain = 60;
            this.text = '请在' + remain + 's后重试';

            this.int = setInterval(() => {
                if (remain != 0) {
                    remain--;
                    this.text = '请在' + remain + 's后重试';
                } else {
                    clearInterval(this.int);
                    this.showBtn = true;
                }
            }, 1000);
        },
        init() {
            this.$get(
                `${this.$store.state.api.kjsUrl}/web/home/producttypes.json`,
                (data) => {
                    this.productTypes = data;
                }
            );
        },
        validateMobile() {
            if (!this.form.mobile) {
                showMsgBox('请输入手机号');
                return false;
            } else if (!/^[1][0-9]{10}$/.test(this.form.mobile)) {
                showMsgBox('手机号格式错误');
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>
<style lang='scss' scoped>
.wd {
    width: 208px;
}
.container {
    background-color: #264b98;
    padding: 50px 0px 54px 0px;
    text-align: center;
    color: #fff;
    .content {
        padding: 0px 20px;
    }

    .title {
        font-size: 24px;
    }
    .sub-title {
        margin-top: 23px;
        margin-bottom: 40px;
        font-size: 14px;
        color: #afc8ff;
    }
    .form {
        display: flex;
        justify-content: space-between;
    }

    .bottom {
        display: flex;
        justify-content: center;
        margin-top: 38px;

        .btn {
            width: 140px;
            height: 36px;
            background-color: #107eec;
            border-radius: 2px;
            font-size: 14px;
            line-height: 36px;
            cursor: pointer;
        }
    }
}
::v-deep {
    .el-input__inner {
        background-color: transparent !important;
        border-color: #6e93e0;
        border-radius: 0px;
        padding: 0px 12px;
        font-size: 12px;
        color: #afc8ff;
    }

    .el-input-group__append,
    .el-input-group__prepend {
        background-color: transparent !important;
        border-color: #6e93e0;
        border-radius: 0px;
        padding: 0px 12px;
        font-size: 12px;
        color: #fff;
        cursor: pointer;
    }
}
</style>
