// 判断当前设备是pc端 还是手机端

export default {
    methods: {
        //true： 移动端   false： pc端
        isMobile() {
            let userAgentInfo = navigator.userAgent;
            let Agents = [
                "Android",
                "iPhone",
                "SymbianOS",
                "Windows Phone",
                "iPad",
                "iPod",
            ];
            let getArr = Agents.filter((i) => userAgentInfo.includes(i));

            return getArr.length ? true : false;
        },
    },
    mounted() {
        // let isMobile = this.isMobile();
        // let storage = getSession("isWeb") === "true" ? true : false;

        // if (storage !== isMobile) {
        //     setSession("isWeb", isMobile);

        //     if (isMobile) {
        //         window.location.replace(this.$store.state.platform.wapHome);
        //     }
        // }
    },
};
