<template>
    <div>
        <nuxt />
    </div>
</template>

<script>
import IS_MODEL from '@mixins/is-model';
export default {
    mixins:[IS_MODEL],
    methods: {},
};
</script>
<style scoped lang="scss">
</style>
