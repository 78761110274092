import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    data: {},

    dialogLoginVisible: false,
    loginCallback: null,
});

export const mutations = {
    updateData(state, n) {
        state.data = n;
        storage.setSession({
            userinfo: n,
        });
    },
    updateDialogLoginVisible(state, n) {
        state.dialogLoginVisible = n;
    },
    setLoginCallback(state, n) {
        state.loginCallback = n;
    },
};

export const getters = {
    getData(state) {
        var data = {};
        if(process.browser) {
            let stateData = state.data || {},
                keys = Object.keys(stateData);

            if (keys.length) {
                data = state.data;
            } else {
                data = storage.getSession('userinfo')
            }
        }
        return data;
    },
    // 用户昵称
    getNickname(state, getters) {
        return getters.getData.mobile;
    },
    //用户头像
    getHeadportraitpath(state, getters) {
        return getters.getData.headportraitpath;
    },
    getType(state, getters) {
        // P: 普通用户

        return getters.getData.type;
    },
    getDialogLoginVisible(state, getters) {
        return state.dialogLoginVisible;
    },
};

export const actions = {
    /**
     * 请求用户身份信息，仅在有SERIAL_CODE时请求
     * @param {Object} state state实例
     * @param {Object} obj 参数
     * @returns 
     */
    queryUserInfo(state, obj = {requery: false}) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

        if (!SERIAL_CODE) return;

        var query = () => {
            Vue.prototype.$get(`${this.state.api.webUrl}/userlogin/userinfo.json`, data => {
                state.commit('updateData', data);

                // 如果客户端
                if (window.electronApi) {
                    //data为用户信息
                    window.electronApi.dispatch('login', data);
                }
            });
        };

        if (obj.requery) {
            query();
        } else {
            if (state.getters.getNickname) return;

            query();
        }
    },
    /**
     * 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
    smsloginHandler(state, {data, success, complete, error}) {
        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.authUrl}/login`,
            data: {
                ...data,
            },
            callback: data => {
                state.commit('updateData', data);

                Vue.prototype.setToken(data.accessToken);
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },

    /**
     * 手机号 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
    phoneloginHandler(state, {data, success, complete, error}) {
        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.authUrl}/phone-login`,
            data: {
                ...data,
            },
            callback: data => {
                state.commit('updateData', data);

                Vue.prototype.setToken(data.accessToken);
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },

    /**
     * 手机号 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
     caLoginHandler(state, {data, success, complete, error}) {
        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.authUrl}/ca-login`,
            data: {
                ...data,
            },
            callback: data => {
                state.commit('updateData', data);

                Vue.prototype.setToken(data.accessToken);
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },

    
    /**
     * 退出登录，成功后清空用户信息缓存
     * @param {Object} state state实例
     * @returns 
     */
    logoutHandler(state) {
        Vue.prototype.$post(`${this.state.api.authUrl}/logout`, () => {
            window.location.reload();
            
            state.commit('updateData', {});

            Vue.prototype.removeToken();
        });
    },
    nuxtServerInit({commit}, {req}) {
        // console.log(req.headers.cookie);
    },
    showLogin({commit}, cb) {
        if (cb) {
            commit('setLoginCallback', cb);
        } else {
            commit('setLoginCallback', null);
        }

        commit('updateDialogLoginVisible', true);
    },
    loginCallbackDispatch({state, commit}) {
        var callback = state.loginCallback;

        if (callback && typeof callback === 'function') {
            callback();

            commit('setLoginCallback', null);
        }
    },
    checkIsLogin(state, callback) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');	

        if (SERIAL_CODE) {
            // 已登录

            callback && callback();
        } else {
            // 未登录

            state.dispatch('showLogin', () => {
                callback && callback();
            });
        }
    },
};