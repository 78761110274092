import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d00e880 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _7c128ec4 = () => interopDefault(import('..\\pages\\achievement\\index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _3c873b54 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _50be09a3 = () => interopDefault(import('..\\pages\\products\\index.vue' /* webpackChunkName: "pages/products/index" */))
const _3e08faec = () => interopDefault(import('..\\pages\\pcas\\loading.vue' /* webpackChunkName: "pages/pcas/loading" */))
const _2528ea09 = () => interopDefault(import('..\\pages\\products\\details\\gong-cheng-xiang-mu.vue' /* webpackChunkName: "pages/products/details/gong-cheng-xiang-mu" */))
const _652980b3 = () => interopDefault(import('..\\pages\\products\\details\\qi-ye-jiao-pei.vue' /* webpackChunkName: "pages/products/details/qi-ye-jiao-pei" */))
const _d64a81ce = () => interopDefault(import('..\\pages\\products\\details\\qi-ye-zhao-cai.vue' /* webpackChunkName: "pages/products/details/qi-ye-zhao-cai" */))
const _0b54a42f = () => interopDefault(import('..\\pages\\products\\details\\xing-zheng-fu-wu.vue' /* webpackChunkName: "pages/products/details/xing-zheng-fu-wu" */))
const _19237328 = () => interopDefault(import('..\\pages\\products\\details\\yun-zi-kong-jian.vue' /* webpackChunkName: "pages/products/details/yun-zi-kong-jian" */))
const _7b7369c1 = () => interopDefault(import('..\\pages\\products\\details\\zhao-biao-cai-gou.vue' /* webpackChunkName: "pages/products/details/zhao-biao-cai-gou" */))
const _64328de2 = () => interopDefault(import('..\\pages\\news\\assets\\js\\news.js' /* webpackChunkName: "pages/news/assets/js/news" */))
const _23b626ea = () => interopDefault(import('..\\pages\\news\\_guid.vue' /* webpackChunkName: "pages/news/_guid" */))
const _62e32d47 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6d00e880,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/achievement",
    component: _7c128ec4,
    alias: "/achievement/index.html",
    name: "achievement"
  }, {
    path: "/news",
    component: _3c873b54,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/products",
    component: _50be09a3,
    alias: "/products/index.html",
    name: "products"
  }, {
    path: "/pcas/loading",
    component: _3e08faec,
    alias: "/pcas/loading/index.html",
    name: "pcas-loading"
  }, {
    path: "/products/details/gong-cheng-xiang-mu",
    component: _2528ea09,
    alias: "/products/details/gong-cheng-xiang-mu/index.html",
    name: "products-details-gong-cheng-xiang-mu"
  }, {
    path: "/products/details/qi-ye-jiao-pei",
    component: _652980b3,
    alias: "/products/details/qi-ye-jiao-pei/index.html",
    name: "products-details-qi-ye-jiao-pei"
  }, {
    path: "/products/details/qi-ye-zhao-cai",
    component: _d64a81ce,
    alias: "/products/details/qi-ye-zhao-cai/index.html",
    name: "products-details-qi-ye-zhao-cai"
  }, {
    path: "/products/details/xing-zheng-fu-wu",
    component: _0b54a42f,
    alias: "/products/details/xing-zheng-fu-wu/index.html",
    name: "products-details-xing-zheng-fu-wu"
  }, {
    path: "/products/details/yun-zi-kong-jian",
    component: _19237328,
    alias: "/products/details/yun-zi-kong-jian/index.html",
    name: "products-details-yun-zi-kong-jian"
  }, {
    path: "/products/details/zhao-biao-cai-gou",
    component: _7b7369c1,
    alias: "/products/details/zhao-biao-cai-gou/index.html",
    name: "products-details-zhao-biao-cai-gou"
  }, {
    path: "/news/assets/js/news",
    component: _64328de2,
    alias: "/news/assets/js/news/index.html",
    name: "news-assets-js-news"
  }, {
    path: "/news/:guid",
    component: _23b626ea,
    alias: "/news/:guid/index.html",
    name: "news-guid"
  }, {
    path: "/",
    component: _62e32d47,
    alias: "/index.html",
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
