<template>
    <header
        class="header"
        :class="{fixed: fixed}"
    >

        <div class="content">
            <div class="header-inner ">
                <div class="logo-con">
                    <img
                        class="logo header-com"
                        src="/config/logo.png"
                    />
                </div>

                <div class="nav-con">
                    <div class="nav-list">
                        <template v-for="(item, index) in $store.state.config.nav">

                            <a
                                v-if="item.isExternalLink"
                                :href="item.href"
                                :key="item.title"
                                target="_blank"
                                class="nav-item"
                            >{{item.title}}</a>

                            <nuxt-link
                                class="nav-item"
                                :key="index"
                                :to="item.href"
                                :class="{active: isActive(item)}"
                                @click="clickHandler($event, item.click)"
                                v-else
                            >
                                {{item.title}}
                            </nuxt-link>
                        </template>
                    </div>
                </div>

                <!-- <div class="search-con">
                    <search></search>
                </div> -->

                <div class="info">
                    <div class="tel">
                        <i class="el-icon-phone icon"></i>
                        {{$store.state.config.baseInfo.tel}}
                    </div>
                </div>
            </div>

            <slot></slot>
        </div>

    </header>
</template>

<script>
import Search from './components/search';

export default {
    components: {
        Search,
    },
    props: {
        theme: {
            type: String,
            default: '',
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        isActive(item) {
            var path = this.$route.path,
                href = item.href,
                activeReg = item.activeReg;

            if (href === path) {
                return true;
            }

            if (path === href) {
                return true;
            }

            // if (activeReg && activeReg.test(path)) {
            //     return true;
            // }

            return false;
        },
        clickHandler(e, cb) {
            if (cb) {
                e.preventDefault();

                var fun = new Function(cb);
                fun.call(this);
            }
        },
    },
    mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@css/var.scss';

.header {
    overflow: hidden;
    cursor: default;
    height: 74px;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 60px;

    &.fixed {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        min-width: 1200px;
    }

    .header-inner {
        display: flex;
        flex: 1;
    }

    .logo-con {
        display: flex;
        align-items: center;

        img.logo {
            // width: 174px;
            height: 36px;
        }

        .name {
            color: $primaryColor;
            font-size: 16px;
            font-weight: bold;
            position: relative;
            margin-left: 1.8em;
            white-space: nowrap;

            &:before {
                content: '';
                display: block;
                height: 100%;
                width: 1px;
                position: absolute;
                left: -0.9em;
                top: 0;
                background: $primaryColor;
            }
        }
    }

    .nav-con {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .nav-list {
            font-size: 16px;
            overflow: hidden;
            margin-left: 100px;

            .nav-item {
                display: block;
                float: left;
                cursor: pointer;
                color: #575d6c;
                position: relative;
                line-height: 74px;
                font-size: inherit;
                height: 74px;

                &:hover {
                    font-weight: bolder;
                    color: $primaryColor;
                }

                // & + .nav-item {
                    // margin-left: 4em;
                    padding: 0 1.5em;
                // }

                &.active {
                    font-weight: bolder;
                    color: $primaryColor !important;
                    border-bottom: 4px solid $primaryColor;
                }
            }
        }
    }

    .search-con {
        display: flex;
        align-items: center;
    }

    .info{
        display: flex;
        align-items: center;
        margin-left: 6px;

        .tel{
            display: flex;
            align-items: center;
            color: $primaryColor;
            font-size: 20px;

            .icon{
                font-size: 24px;
                margin-right: 6px;
            }
        }
    }
}

@media only screen and (max-width: 1660px) {
    //xl

    .header {
        padding: 0 40px;

        .nav-con {
            .nav-list {
                margin-left: 60px;

                .nav-item {
                    // & + .nav-item {
                        // margin-left: 2em;
                        padding: 0 1em;
                    // }
                }
            }
        }
    }
}

@media only screen and (max-width: 1380px) {
    //xl

    .header {
        padding: 0 10px;

        .nav-con {
            .nav-list {
                margin-left: 1em;

                .nav-item {
                    // & + .nav-item {
                        // margin-left: 1em;
                        padding: 0 0.5em;
                    // }
                }
            }
        }
    }
}
</style>
