<template>
    <div class="layout-default">
        <my-header 
            id="myHeader"
            class="page-header"
        ></my-header>

        <div class="page-body">
            <slot>
                <nuxt />
            </slot>
        </div>

        <my-footer></my-footer>
    </div>
</template>

<script>
import IS_MODEL from '@mixins/is-model';
export default {
    mixins: [IS_MODEL],
    name: 'layout-default',
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.layout-default {
    background-color: #f5f7fd;
    width: 100%;
    height: 100%;
    overflow: auto;

    .page-header{
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 999;
        
    }

    .page-body{
        margin-top: 74px;
    }
}

// .page-body {
// }
</style>
