import {api} from '../static/config/index.js';

export const state = () => ({
    ...api,

    kjsUrl: '/kjsite', // 文章新闻
    staFileUrl: '/standalonfile',  //文件相关

})

export const mutations = {
    
}

export const getters = {
    
}
