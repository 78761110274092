const ENV = {
  dev: {
    proxyTarget: "http://www.zhbykj.com",
    fileTarget:"http://www.zhbykj.com",
    fileUpload: "http://www.zhbykj.com",
  },
  prod: {
    proxyTarget: "http://127.0.0.1:10066",
    fileTarget:"http://127.0.0.1:12000",
    fileUpload: "http://127.0.0.1:12000",
  },
  test: {
    proxyTarget: "http://127.0.0.1:10066",
    fileTarget:"http://127.0.0.1:12000",
    fileUpload: "http://127.0.0.1:12000",
  },
};

const ENV_MODE = ENV[process.env.MODE || "prod"];

import CONFIG_PROJECT from './project'

export default {
  head: {
    title: CONFIG_PROJECT.title,
    meta: {
      description: CONFIG_PROJECT.description,
      keywords: CONFIG_PROJECT.keywords,
    },
    icon: "/config/favicon.ico",
  },
  env: ENV_MODE,
  plugins: {
    element: true,
    myComs: true,
    vuexStorage: false,
    jquery: true,
    sentry: false,
    cnzz: false,
  },
  proxy: {
    "/kjsite": {
        target: ENV_MODE.proxyTarget,
        changeOrigin: true,
    },
    '/standalonfile': {
        target: ENV_MODE.fileTarget,
        changeOrigin: true,
    },
    '/upload': {
        target: ENV_MODE.fileUpload,
        changeOrigin: true,
    },

  },
};
